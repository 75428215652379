import support from "../../static/contactUs/support.jpg"
import inquiry from "../../static/contactUs/inquiries.jpg"
export const title = 'How can we help you?';

export const list = ['Find the right solution for you', 'Understand options for demos and pricing', 'Submit general inquires']
export const cards = [{title:"I’m looking for<br/> Accruent support", description:"I’m already an Accruent customer and I need to contact my support team for assistance with my product or services.", buttonText:"Take me to Support >", icon:support, url:"https://www.accruent.com/services-support/customer-support", showFromOnClick: false},
{title:"I have a<br/> general inquiry", description:"I am not a current customer, but I’m looking for pricing info, a demo, or to contact a sales representative.", buttonText:"Take me to General Inquiries >", icon:inquiry, url:"https://www.accruent.com/contact-us", showFromOnClick: true}]

export const fieldEmbedCode =`<script src="//app-abc.marketo.com/js/forms2/js/forms2.min.js"></script>
<form id="mktoForm_1517"></form>
<script>MktoForms2.loadForm("//app-abc.marketo.com", "167-BOY-362", 1517, function(form) {
    dataLayer.push({
      'event': 'gaTriggerEvent',
      'gaCategory': 'CTA Engagement',
      'gaAction': 'Form Load',
      'gaLabel': 'Contact Us',
    });
    form.onSuccess(function(values, followUpUrl) {
      dataLayer.push({
        'event': 'gaTriggerEvent',
        'gaCategory': 'CTA Engagement',
        'gaAction': 'Form Submission',
        'gaLabel': 'Contact Us',
      });
    });
  });
</script>`;
