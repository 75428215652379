import React, { useState, useEffect } from "react";
import extractMarketoValues from "src/components/helpers/extractMarketoIdAndSerial";
import useMarketoForm from "src/components/helpers/useMarketoForm";
import { title, cards, fieldEmbedCode } from "src/rawData/contactus";
import clock from "../../static/contactUs/reply-clock-light.svg"
import getOGMarkup from "src/components/helpers/getOGMarkup";
import { SEO } from "src/components/commonComponents";
import { LocalizedLink } from "../components/commonComponents/localizedLink";
import PopupWithForm from "src/components/commonComponents/forms/PopupWithForm";

const ContactUs = () => {
  const { id, serial, gaLabel } = extractMarketoValues(fieldEmbedCode);
  const [isFormLoaded, setFormLoaded] = useState(false)
  const [formClass, setFormClass] = useState("tw-hidden")
  const [isOpen, setIsOpen] = useState(true);
  const formCallback = () => {
    setFormLoaded(true)
  }
  const { formTag } = useMarketoForm(serial, id, formCallback, "", gaLabel);
  const OGMarkup = getOGMarkup("Contact Us");

  const openModal = e => {
    e.preventDefault();
    setFormClass('tw-block');
  };

  useEffect(() => {
    const handleClick = () => {
      setFormClass('tw-hidden');
    };

    // Add event listener when component mounts
    const modalCloseButtons = document.querySelectorAll('.modal-close.is-large');
    modalCloseButtons.forEach(button => {
      button.addEventListener('click', handleClick);
    });

    // Clean up event listener when component unmounts
    return () => {
      modalCloseButtons.forEach(button => {
        button.removeEventListener('click', handleClick);
      });
    };
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  return (
    <>
       <SEO
        title = "Contact Us Today | Accruent"
        description = "Get in touch with Accruent's team today for support, sales inquiries, or partnership opportunities. Visit our Contact Us page for more info!"
        pageUrl="/contactus"
        OGMarkup={OGMarkup}
      />
    <section className="static-contact-us tw-bg-white md:before:tw-content-[''] md:before:tw-w-full md:before:tw-h-full md:before:tw-absolute md:before:tw-right-0 md:before:tw-bg-no-repeat md:before:tw-bg-auto md:before:tw-z-[2] md:before:tw-bg-[url('../../static/contactUs/background.svg')]">
      <div className="lg:tw-flex">
        <div className="tw-pt-[52px] lg:tw-pb-0 lg:tw-pt-[57px] tw-w-full">
          <div className="container tw-max-w-[550px] !tw-m-auto tw-px-4 md:tw-px-12 lg:tw-my-[12px] tw-pb-[52px] md:!tw-pb-[155px]">
            <h1 className="tw-pb-[10px] tw-text-xxxlFont tw-leading-[46.4px] md:tw-text-[56px] md:tw-tracking-[0.5px]">{title}</h1>
            <div className="tw-pt-[10px] md:tw-pt-0 tw-text-[22px] tw-leading-[26.4px] md:tw-text-pxSize5 tw-text-[#3A3A3A] tw-font-Roboto tw-font-normal">
                Please select from the options below.
            </div>            
          </div>
          <div className="card-view-contact tw-mt-[150px] tw-relative before:tw-content-[''] before:tw-w-full before:tw-h-full before:tw-block before:tw-box-border before:tw-absolute before:tw-bg-accruent_gray">
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between container tw-px-4 md:tw-px-12 tw-max-w-[550px] !tw-m-auto">
            {cards?.length && cards.map((card, index) => {
                return (<div className={((cards.length-1 === index) ? "md:-tw-mt-[165px] tw-mt-4":"-tw-mt-[117px] md:-tw-mt-[165px] md:tw-mr-4")  + " tw-relative tw-z-[2] tw-h-full tw-bg-white tw-block tw-shadow-[0px_12px_24px_#00000029] tw-rounded-[48px] tw-max-w-[572px] tw-px-4 md:tw-px-8 tw-pb-8"}>             
                <div className="-tw-mt-[75px] tw-hidden md:tw-block">
                   <img className="tw-w-[150px] tw-rounded-full tw-m-auto" src={card.icon}/>
                </div>
                <center className="tw-pt-8 tw-text-mobileXxlFont md:tw-text-[34px] tw-font-bold tw-font-Poppins tw-text-accruent_black_input" dangerouslySetInnerHTML={{ __html: card.title }}></center>
                <center className="tw-pt-3 tw-max-w-[508px] tw-m-auto tw-font-Roboto tw-text-[18px] md:tw-text-[22px] tw-tracking-[0.15px] tw-text-accruent_black_input tw-font-medium">
                 {card.description}
                </center>
                <LocalizedLink
                    onClick={card.showFromOnClick ? openModal : null}
               to={!card.showFromOnClick ? card.url : null}
               className="tw-w-full tw-text-center tw-pt-[17px] tw-border tw-rounded-[12px]
               tw-bg-accruent_sapphire tw-text-accruent_white tw-font-bold tw-font-Poppins tw-tracking-[1.42px]
               tw-h-[51px] tw-leading-[16px] tw-mt-[32px] hover:tw-text-accruent_white tw-inline-block">
               {card.buttonText}
             </LocalizedLink>
             </div>)
            })}
            </div>
            <div className="container tw-max-w-[550px] !tw-m-auto tw-px-4 md:tw-px-12 md:tw-flex !tw-mt-[52px] md:!tw-mt-[73px] !tw-pb-[51px] md:!tw-pb-[83px]">
            <div className="tw-flex tw-justify-center tw-pb-10 md:tw-pb-0"><img src={clock} className="tw-w-[75px]"/></div>
            <div className="md:tw-pl-8">
                <h4>Response Time at Accruent</h4>
                <ul className="tw-pt-4 tw-text-[18px] tw-font-Roboto tw-font-normal tw-text-accruent_black_olive">
                    <li>- Our team will reach out to you as soon as they receive your contact.</li>
                    <li>- Expect a response by call or email from 1- 3 business days.</li>
                    <li>- Make sure to add the domain accruent.com to be able to receive our emails.</li>
                    <li>- Our Working hours is <span className="tw-font-bold">Mon-Friday 9 to 5 pm CST (GMT-6).</span></li>
                </ul>
            </div>
          </div>
          </div>          
        </div>       
      </div>
      {<div className={`contact-us-form-popup form-page-wrapper four-box-watch-demo-wrapper ${formClass}`}>
        <PopupWithForm
              key={isOpen}
              form={formTag}
              isFormLoaded={isFormLoaded}
              popup={isOpen}
              // closeModal={closeModal}
            />
      </div>
        }
    </section>
    </>
  );
};

export default ContactUs;